import HomePage from '../pages/HomePage.jsx'
import ContentPage from '../pages/ContentPage.jsx'
import InformationPage from '../pages/InformationPage.jsx'
import FaqPage from '../pages/FaqPage.jsx'

export const APP_ROUTES = [
  {
    path: '/',
    exact: true,
    component: HomePage
  },
  {
    path: 'info',
    exact: true,
    component: InformationPage
  },
  {
    path: 'info/:slug',
    exact: true,
    component: ContentPage
  },
  {
    path: 'faq',
    exact: true,
    component: FaqPage
  },
  {
    path: ':slug',
    exact: true,
    component: ContentPage
  }
]
