export const transformImage = (image = '', options = {}) => {
  // Guard
  if (!image || !Object.keys(options).length) return ''

  // Extract the path from the image URL
  const [, path] = /.com(\/.+)/.exec(image)

  // create image url pointing to the Storyblok image service
  return `https://img2.storyblok.com/${options.width}x${options.height}${path}`
}
