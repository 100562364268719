import React from 'react'
import PropTypes from 'prop-types'
import {
  StoryblokComponent,
  storyblokEditable
} from '@storyblok/react'

export default function Page ({ blok }) {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      {blok.body
        ? blok.body.map((nestedBlok) => (
            <div key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </div>
        ))
        : null}
    </div>
  )
}

// PropTypes validation
Page.propTypes = {
  blok: PropTypes.shape({
    _uid: PropTypes.string.isRequired,
    body: PropTypes.arrayOf(PropTypes.shape({
      _uid: PropTypes.string.isRequired
    }))
  }).isRequired
}
