import { useState, useEffect } from 'react'
import { useStoryblok } from '@storyblok/react'

export default function useStoryLoader(path, options) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const story = useStoryblok(path, options)

  useEffect(() => {
    setLoading(true)
    if (story) {
      if (!story.content) {
        let message
        if (!navigator.onLine) {
          // Show offline message.
          message = 'Your internet connection is bad, try again later'
        } else {
          // Show 404 if content is missing or incorrect
          message = '404: Page not found'
        }
        setError(message)
      } else {
        setError('') // Clear any previous errors
      }
      setLoading(false) // Set loading false when story updates
    }
  }, [story, path])

  return { story, loading, error }
}
