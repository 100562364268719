import React from 'react'
import PropTypes from 'prop-types'

import Title from '../header/Title.jsx'

/**
 * Renders an error component that displays an error message.
 * @param {string} message - The error message.
 * @returns {React.ReactElement} The error component, which displays the error message.
 */
export default function Error({ message }) {
  return (
    <div className="relative z-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white mt-36 md:mt-52">
      <div className="mb-12 md:mb-20">
        <Title title={message} />
      </div>
    </div>
  )
}

// PropTypes validation
Error.propTypes = {
  message: PropTypes.string.isRequired
}
