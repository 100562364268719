import React from 'react'
import { Helmet } from 'react-helmet'

import Title from '../components/header/Title.jsx'

export default function Homepage() {
  return (
    <>
      <Helmet>
        <title>Pon x DGP</title>
      </Helmet>

      <div className="relative z-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white mt-36 md:mt-52">
        <div className="mb-12 md:mb-20">

          <Title title="Bedankt" />

          <div className="flex justify-center items-center">
            <div className="max-w-lg richText mb-8 text-center text-sm lg:text-base">
              <p>
                Het zit erop! De vierde editie van de Formule 1 Heineken Dutch Grand Prix met een stormachtige wind en de tweede plaats voor Max.
                Gelukkig was er ook ruimte voor de zon, adembenemende snelheden, spannende acties en een uitzinnig feestelijke sfeer op Circuit Zandvoort.
              </p>

              <p>
                Het was een genoegen om je te mogen verwelkomen op onze Park & Bike-locaties, in de Ben Pon lounge en natuurlijk op de Ben Pon Grandstand.<br />
                We willen je bedanken voor je komst en enthousiasme tijdens een fantastisch raceweekend in de Zandvoortse duinen.
              </p>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
