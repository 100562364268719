import React from 'react'
import ReactDOM from 'react-dom/client'
import { storyblokInit, apiPlugin } from '@storyblok/react'

import './index.css'
import App from './App.jsx'
import reportWebVitals from './reportWebVitals.js'
import Page from './components/storyblok/page.jsx'
import Content from './components/storyblok/content.jsx'
import * as setupServiceWorker from './setupServiceWorker.js'

storyblokInit({
  accessToken: import.meta.env.VITE_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    page: Page,
    content: Content
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

setupServiceWorker.register()
reportWebVitals()
