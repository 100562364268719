import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import { isActive } from '../../helpers/navigation.jsx'

export default function Footer({ menuOpen, racingLinesBackground = true }) {
  const location = useLocation()

  return (
    <footer className={`bg-gradient-primary relative ${racingLinesBackground && "before:content-[''] before:absolute before:bottom-0 before:h-screen before:w-screen before:left-0 before:z-0 before:bg-[url('/racing-lines.png')] before:bg-left-bottom before:bg-contain before:bg-no-repeat"}`} {...(menuOpen ? { inert: '' } : {})} aria-hidden={menuOpen ? 'true' : 'false'}>
      <div className="relative z-10 mx-auto max-w-screen-xl py-8 mt-4">
        <nav className="flex flex-row justify-center space-y-0 space-x-4">
          <Link
            to='privacyverklaring'
            className={`hover:text-tertiary ${isActive(location.pathname, '/privacyverklaring') ? 'text-tertiary' : 'text-white'} text-center sm:text-left text-sm`}
          >
            Privacyverklaring
          </Link>
          <Link
            to='cookiebeleid'
            className={`hover:text-tertiary ${isActive(location.pathname, '/cookiebeleid') ? 'text-tertiary' : 'text-white'} text-center sm:text-left text-sm`}
          >
            Cookiebeleid
          </Link>
        </nav>
        <div className="text-center mt-2 md:mt-8">
          <Link to="/" className="inline-block mt-8 lg:mt-0">
            <img
              src="/pon-f1-logo.svg"
              alt="Event Supporter Formula 1 Heineken Dutch Grand Prix 2024"
              className="w-[200px] md:w-[240px]"
            />
          </Link>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  menuOpen: PropTypes.bool,
  racingLinesBackground: PropTypes.bool
}
