import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * This component ensures that the window scrolls to the top whenever the user navigates to a new route.
 * @returns {null} This component does not render any visible output.
 */
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
