import React from 'react'
import PropTypes from 'prop-types'

import Footer from '../general/Footer.jsx'

export default function Layout({ children, menuOpen, setMenuOpen }) {
  return (
    <>
      <div className="bg-background" {...(menuOpen ? { inert: '' } : {})} aria-hidden={menuOpen ? 'true' : 'false'}>
        {children}
      </div>
      <Footer menuOpen={menuOpen} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func
}
