import React from 'react'
import PropTypes from 'prop-types'

/**
 * Displays a placeholder skeleton screen that can be used during the loading state of a component.
 * This skeleton mimics the size and basic appearance of the content that will eventually be loaded.
 * @param {string} className - Additional CSS class names to style the skeleton loader.
 * @returns {React.ReactElement} A div element styled as a skeleton loader with animation effects.
 */
export default function SkeletonLoader ({ className }) {
  return (
    <div
      className={`motion-safe:animate-pulse ${className}`}
    >
      <div className="flex items-center justify-center bg-gray-300 flex-shrink-0 rounded-xl h-full aspect-video max-w-full">
        <svg
          className="w-10 h-10 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
    </div>
  )
}

SkeletonLoader.propTypes = {
  className: PropTypes.string
}
