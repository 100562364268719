import React, { useState } from 'react'
import { renderRichText } from '@storyblok/react'
import PropTypes from 'prop-types'

/**
 * Renders a single FAQ item element.
 * @param {object} props - The properties passed to the FaqItem component.
 * @returns {React.ReactElement} - A single FAQ item element.
 */
export default function FaqItem(props) {
  const { faqItem, isFirst } = props
  // Only show read more option if the horizontal rule is added.
  const showReadMore = faqItem?.content?.answer?.content.some(e => e.type === 'horizontal_rule')
  const [showAllText, setShowAllText] = useState(false)

  return (
    <div className={`${isFirst ? '' : 'pt-8'} lg:grid lg:grid-cols-12 lg:gap-8`}>
      <dt className="font-medium lg:col-span-5">{faqItem.name}</dt>
      <dd className="mt-4 lg:col-span-7 lg:mt-0">
        <div
          className={`richText [&>hr]:hidden ${showAllText ? '' : '[&>hr~*]:hidden'}`}
          dangerouslySetInnerHTML={{
            __html: renderRichText(faqItem?.content?.answer)
          }}
        />
        {showReadMore && (
          <button
            className={`relative text-tertiary underline underline-offset-2 hover:text-secondary focus:text-secondary ${showAllText ? 'mt-4' : ''}`}
            onClick={() => setShowAllText(!showAllText)}
          >
            {showAllText ? 'Inklappen' : 'Lees meer'}
          </button>
        )}
      </dd>
    </div>
  )
}

FaqItem.propTypes = {
  faqItem: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.shape({
      answer: PropTypes.shape({
        content: PropTypes.array
      })
    })
  }).isRequired,
  isFirst: PropTypes.bool
}
