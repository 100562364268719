import React, { useState, useEffect } from 'react'
import { useStoryblokApi } from '@storyblok/react'
import { Link } from 'react-router-dom'
import { STORYBLOK_PREFIX, STORYBLOK_LOCATION } from '../constants/Api.js'
import { TEXT } from '../constants/textConstants.js'
import Image from '../components/general/Image.jsx'
import Loading from '../components/general/Loading.jsx'
import Error from '../components/general/Error.jsx'
import HeaderImage from '../components/header/HeaderImage.jsx'
import Title from '../components/header/Title.jsx'
import { Arrow } from '../components/icons/Arrow.jsx'

export default function InformationPage() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const storyblokApi = useStoryblokApi()

  useEffect(() => {
    setLoading(true)
    Promise.all([
      storyblokApi.get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}info`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: true
      }),
      storyblokApi.get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}info`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: false
      })
    ]).then(([startpageResponse, storiesResponse]) => {
      setData({
        startpage: startpageResponse.data?.stories[0],
        stories: storiesResponse.data?.stories
      })
      setLoading(false)
    }).catch(error => {
      let message
      if (!navigator.onLine) {
        // Show offline message.
        message = 'Your internet connection is bad, try again later'
      } else {
        // Show error message.
        message = error.message.toString()
      }

      setError(message)
      setLoading(false)
    })
  }, [storyblokApi])

  if (loading) {
    return <Loading isLoading={true} />
  }

  if (error) {
    return <Error message={error} />
  }

  return (
    <>
      <HeaderImage content={data?.startpage?.content} />

      {/* Content */}
      <div className="relative z-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white mt-36 md:mt-52">
        <div className="mb-12 md:mb-20">

          <Title title={data?.startpage?.name} />

          {data?.stories && data?.stories?.length > 0
            ? (
              <div className="grid md:grid-cols-2 gap-10 2xl:gap-16">
                {data?.stories.map((story, index) => (
                  <div key={index}>
                    <Link to={story?.slug} tabIndex="-1">
                      <Image
                        image={story?.content?.header}
                        imgClasses="rounded-xl"
                      />
                    </Link>
                    <h2 className="text-lg">{story.name}</h2>
                    <Link className="group text-sm text-secondary font-medium flex items-center  gap-x-2 transition-colors duration-200 hover:text-tertiary" to={story?.slug}>
                      Alles over {story.name} <Arrow className="fill-secondary group-hover:fill-tertiary transition-fill duration-200" />
                    </Link>
                  </div>
                ))}
              </div>
            )
            : (
              <p>{TEXT.NO_INFORMATION_FOUND}</p>
            )}
        </div>
      </div>
    </>
  )
}
