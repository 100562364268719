import { useEffect } from 'react'

/**
 * Custom React hook to switch favicon based on the preferred color scheme.
 * @param {string} lightModeFavicon - The path to the light mode favicon.
 * @param {string} darkModeFavicon - The path to the dark mode favicon.
 */
const useThemeAwareFavicon = (lightModeFavicon, darkModeFavicon) => {
  useEffect(() => {
    const favicon = document.getElementById('favicon')
    if (!favicon) return

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    function updateFavicon(e) {
      const darkModeOn = e.matches
      favicon.href = darkModeOn ? darkModeFavicon : lightModeFavicon
    }

    // Initial check and set
    updateFavicon(mediaQuery)

    // Listen for changes
    mediaQuery.addEventListener('change', updateFavicon)

    // Cleanup on component unmount
    // eslint-disable-next-line consistent-return
    return () => mediaQuery.removeEventListener('change', updateFavicon)
  }, [lightModeFavicon, darkModeFavicon])
}

export default useThemeAwareFavicon
