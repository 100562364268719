import React, { useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

/**
 * @returns {React.ReactElement} An install banner for mobile devices.
 */
export default function Installbanner() {
  // We only show the popup on mobile devices and we show it for a maximum of 3 times.
  const [showPopup, setShowPopup] = useState((isIOS || isAndroid) && localStorage.getItem('PWA-popup') < 3)
  const isPWA = window.matchMedia?.('(display-mode: standalone)').matches

  function cancelPopup() {
    setShowPopup(false)
    localStorage.setItem('PWA-popup', localStorage.getItem('PWA-popup') ? parseInt(localStorage.getItem('PWA-popup')) + 1 : 1)
  }

  if (showPopup && !isPWA) {
    return (
      <div>
        <div
          className="bg-gradient-primary opacity-80 left-0 min-h-screen fixed top-0 w-screen z-50"
        ></div>
        <div
          className={`backdrop-blur bg-white ${isIOS ? 'bg-opacity-80 rounded-lg' : ''} bottom-0 text-black brightness-110 left-0 mx-2 mb-2.5 overflow-hidden fixed z-50`}
        >
          <div
            className="flex items-center border-b-[0.5px] justify-between py-3 px-4 border-gray border-opacity-30"
          >
            <p
              className="text-black font-medium leading-[1.125] m-0 p-0"
            >
              Sneller toegang?
            </p>
            <button
              className="text-tertiary text-sm p-0 m-0 border-0 bg-transparent"
              onClick={() => cancelPopup()}
            >
              Annuleer
            </button>
          </div>
          <div
            className="flex w-full"
          >
            <div
              className="border-b-[0.5px] text-inherit mx-4 py-4 w-full border-gray border-opacity-30"
            >
              <p
                className="text-gray text-opacity-60 text-sm leading-4 m-0 p-0"
              >
                Installeer onze app voor een snellere ervaring en directe toegang vanaf je startscherm!
              </p>
            </div>
          </div>
          <div
            className="text-inherit mx-4 py-4"
          >
            {isIOS
              ? (
                <>
                  <div
                    className="flex items-center justify-start text-left mb-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="grow-0 shrink-0 basis-auto h-8 mr-8 w-6 text-darkGray fill-darkGray"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M0 0h24v24H0V0z"
                        fill="none"
                      />
                      <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"/>
                    </svg>
                    <p className="text-gray text-opacity-60 text-sm leading-4 m-0 p-0 font-medium">
                      1. Klik het deel icoon in het menu
                    </p>
                  </div>
                  <div
                    className="flex items-center justify-start text-left"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      className="grow-0 shrink-0 basis-auto h-8 mr-8 w-6 text-darkGray stroke-darkGray fill-darkGray"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Complete">
                        <g id="add-square">
                          <g>
                            <rect
                              data-name="--Rectangle"
                              fill="none"
                              height="20"
                              id="_--Rectangle"
                              rx="2"
                              ry="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              width="20"
                              x="2"
                              y="2"
                            />
                            <line
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              x1="15.5"
                              x2="8.5"
                              y1="12"
                              y2="12"
                            />
                            <line
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              x1="12"
                              x2="12"
                              y1="15.5"
                              y2="8.5"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <p className="text-gray text-opacity-60 text-sm leading-4 m-0 p-0 font-medium">
                      2. Klik op &#39;Zet op beginscherm&#39;
                    </p>
                  </div>
                </>
                )
              : (
                  <>
                    <div
                      className="flex items-center justify-start text-left mb-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="grow-0 shrink-0 basis-auto h-8 mr-8 w-6 text-darkGray fill-darkGray"
                        viewBox="0 -960 960 960"
                      >
                        <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                      </svg>
                      <p className="text-gray text-opacity-60 text-sm leading-4 m-0 p-0 font-medium">
                        1. Klik het instellingen icoon
                      </p>
                    </div>
                    <div
                      className="flex items-center justify-start text-left"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="grow-0 shrink-0 basis-auto h-8 mr-8 w-6 text-darkGray fill-darkGray"
                        viewBox="0 -960 960 960"
                      >
                        <path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h280v80H280v40h280v80H280v480h400v-80h80v200q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm440-240L520-600l56-56 104 104v-288h80v288l104-104 56 56-200 200ZM280-800v-40 40Zm0 640v40-40Z" />
                      </svg>
                      <p className="text-gray text-opacity-60 text-sm leading-4 m-0 p-0 font-medium">
                        2. Klik op &#39;App installeren&#39;
                      </p>
                    </div>
                  </>
                )}
          </div>
        </div>
      </div>
    )
  }

  return null
}
