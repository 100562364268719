import React from 'react'
import { render } from 'storyblok-rich-text-react-renderer'
import PropTypes from 'prop-types'

const Content = ({ blok }) => <div className='richText prose'>{render(blok.richText)}</div>

Content.propTypes = {
  blok: PropTypes.shape({
    richText: PropTypes.object
  })
}

export default Content
