export const TEXT = {
  NO_INFORMATION_FOUND: 'Geen info gevonden.',
  LOADING_ERROR: 'Er is een fout opgetreden bij het laden.',
  DAY_OVERVIEW: 'Programma',
  FRIDAY: 'Vrijdag',
  FRIDAY_DATE: '23 augustus',
  SATURDAY: 'Zaterdag',
  SATURDAY_DATE: '24 augustus',
  SUNDAY: 'Zondag',
  SUNDAY_DATE: '25 augustus'
}
