import React from 'react'
import PropTypes from 'prop-types'

export const Arrow = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893L11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929L8.58579 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H8.58579L5.29289 1.70711C4.90237 1.31658 4.90237 0.683417 5.29289 0.292893Z" />
  </svg>
)

// PropTypes validation
Arrow.propTypes = {
  className: PropTypes.string
}
