import React, { useState, useEffect } from 'react'
import {
  Route,
  Routes,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'

import * as CookieConsent from 'vanilla-cookieconsent'
import { APP_ROUTES } from './constants/AppRoutes.js'
import Layout from './components/layouts/Default.jsx'
import ScrollToTop from './components/general/ScrollToTop.jsx'
import useThemeAwareFavicon from './hooks/useThemeAwareFavicon.jsx'
import './index.css'
import './config/analytics.js'
import Installbanner from './components/general/Installbanner.jsx'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  enabled: import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production',
  tracesSampleRate: 1.0
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function App() {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        analytics: {}
      },
      language: {
        default: 'nl',
        translations: {
          nl: {
            consentModal: {
              title: 'We gebruiken cookies',
              description: 'We gebruiken functionele cookies en beperkt analytische cookies als u onze website bezoekt, om te zorgen dat de website werkt en voor geaggregeerde analyse doeleinden. Als je meer wilt weten over deze cookies, kun je ons <a href="cookiebeleid">Cookiebeleid</a> raadplegen.',
              acceptAllBtn: 'Sluiten'
            }
          }
        }
      }
    })
  }, [])

  // Show themed favicon based on the system settings of the user.
  useThemeAwareFavicon(
    '/favicon-lightmode.png',
    '/favicon-darkmode.png'
  )

  // Track page views with GA
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]) // location dependency is needed to work correctly

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout menuOpen={menuOpen} setMenuOpen={setMenuOpen}>

        <SentryRoutes>
          {APP_ROUTES.map(({ path, exact, component: Component }) => (
            <Route key={path} path={path} exact={exact} element={<Component />} />
          ))}
        </SentryRoutes>
        <Installbanner />
      </Layout >
    </BrowserRouter>
  )
}
