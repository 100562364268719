import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a title in an h1 tag.
 * @param {object} props - The properties passed to the Title component.
 * @param {string} props.title - The text to be displayed in the title.
 * @returns {React.ReactElement} An h1 element containing the title.
 */
export default function Title({ title }) {
  return (
    <h1 className="uppercase text-center mb-8 md:mb-10 text-3xl md:text-6xl">{title}</h1>
  )
}

// PropTypes validation
Title.propTypes = {
  title: PropTypes.string
}
