import React, { useState, useEffect } from 'react'
import { useStoryblokApi, renderRichText } from '@storyblok/react'
import { Helmet } from 'react-helmet'

import { STORYBLOK_PREFIX, STORYBLOK_LOCATION } from '../constants/Api.js'
import Loading from '../components/general/Loading.jsx'
import Error from '../components/general/Error.jsx'
import HeaderImage from '../components/header/HeaderImage.jsx'
import Title from '../components/header/Title.jsx'
import FaqItem from '../components/faq/FaqItem.jsx'
import { Arrow } from '../components/icons/Arrow.jsx'

export default function FaqPage() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const storyblokApi = useStoryblokApi()

  useEffect(() => {
    setLoading(true)
    Promise.all([
      storyblokApi.get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}faq`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: true
      }),
      storyblokApi.get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}faq`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: false,
        per_page: 100
      })
    ]).then(([startpageResponse, storiesResponse]) => {
      setData({
        startpage: startpageResponse.data?.stories[0],
        stories: storiesResponse.data?.stories
      })
      setLoading(false)
    }).catch(error => {
      let message
      if (!navigator.onLine) {
        message = 'Your internet connection is bad, try again later'
      } else {
        message = error.message.toString()
      }

      setError(message)
      setLoading(false)
    })
  }, [storyblokApi])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollTop(true)
      } else {
        setShowScrollTop(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (loading) {
    return <Loading isLoading={true} />
  }

  if (error) {
    return <Error message={error} />
  }

  // Group stories by category
  const groupedStories = data?.stories?.reduce((acc, story) => {
    const { category } = story.content
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(story)
    return acc
  }, {})

  const categories = Object.keys(groupedStories)

  return (
    <>
      <Helmet>
        <title>Pon x DGP - Veelgestelde vragen</title>
      </Helmet>
      <HeaderImage content={data?.startpage?.content} />

      <div className="relative z-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white mt-36 md:mt-52">
        <div className="mb-12 md:mb-20">
          <Title title="Veelgestelde vragen" />

          <div className="flex justify-center items-center">
            <div className="max-w-lg richText mb-8 text-center text-sm lg:text-base"
              dangerouslySetInnerHTML={{ __html: renderRichText(data?.startpage?.content?.content) }}
            />
          </div>

          {/* Accordion with anchor links to categories */}
          <div className="flex justify-center">
            <div className="md:flex md:flex-wrap gap-4 justify-center items-center w-full max-w-2xl mb-6 md:mb-12">
              {categories.map((category, index) => (
                <div key={index}>
                  <a href={`#category-${category}`} className="font-medium underline underline-offset-2 hover:text-tertiary">
                    {category}
                  </a>
                </div>
              ))}
            </div>
          </div>

          {data?.stories && data?.stories?.length > 0
            ? (
              <div>
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex} id={`category-${category}`} className="category-section mb-12 scroll-mt-12">
                    <h2 className="font-medium text-2xl">{category}</h2>
                    <dl className="space-y-8 divide-y-2 divide-white divide-opacity-50">
                      {groupedStories[category].map((story, storyIndex) => (
                        <FaqItem key={`${category}-${storyIndex}`} faqItem={story} isFirst={storyIndex === 0} />
                      ))}
                    </dl>
                  </div>
                ))}
              </div>
              )
            : (
              <p>Geen vragen gevonden.</p>
              )}

          <div className="flex justify-center">
            <div className="flex flex-wrap justify-center items-center w-full max-w-2xl text-center mt-8 px-8 italic text-sm">
              <p>
                Voor overige vragen kun je contact opnemen met degene via wie je de tickets hebt verkregen.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="bottom-6 right-6 md:bottom-12 md:right-12 cursor-pointer z-20 shadow bg-gradient-button rounded-full p-4 fixed transition-all duration-500 ease-in-out text-white hover:bg-gradient-button-reverse"
        >
          <Arrow className="fill-white transform -rotate-90" />
        </button>
      )}
    </>
  )
}
