import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { transformImage } from '../../utils/image-utils.js'
import SkeletonLoader from './SkeletonLoader.jsx'

/**
 * Renders an image with optional full-width styling and dynamic source handling.
 * This component also manages loading states and displays a skeleton loader during image loading.
 * @param {object} props - The properties passed to the Image component.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.srcSet - The srcSet attribute for responsive image display.
 * @param {string} props.label - Caption text for the image.
 * @param {string} props.alt - Alternative text for the image.
 * @param {string} props.className - CSS class names for the figure element.
 * @param {string} props.imgClasses - CSS class names for the img element.
 * @param {object} props.image - The image object containing the filename and optionally alt text.
 * @param {boolean} props.isFullWidth=false - Flag indicating if the image should span the full width of its container.
 * @returns {React.ReactElement} A figure element wrapping the image and a skeleton loader component.
 */
export default function Image(props) {
  const [srcSet, setSrcSet] = useState('')
  const [src, setSrc] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const { src: imgSrc, srcSet: imgSrcSet, image, isFullWidth = false } = props
  const imgFile = image?.filename

  const computeImages = useCallback(() => {
    const computedSrc = imgSrc || `${transformImage(imgFile, { width: 480, height: 0 })} 480w`
    setSrc(computedSrc)

    const transformedImages = [
      `${transformImage(imgFile, { width: 640, height: 0 })} 640w`,
      `${transformImage(imgFile, { width: 768, height: 0 })} 768w`
    ]

    const computedSrcSet = imgSrcSet || transformedImages.join(', ')
    setSrcSet(computedSrcSet)
  }, [imgSrc, imgSrcSet, imgFile])

  useEffect(() => {
    computeImages()
  }, [computeImages])

  return (
    <>
      <figure
        className={`${props.className || ''} ${isFullWidth ? 'w-full' : ''}`}
        style={{ display: isLoading ? 'none' : 'block' }}
      >
        <img
          className={`object-cover ${isFullWidth ? 'absolute inset-0 w-full h-[40vh] object-cover' : 'object-center rounded-[inherit]'} ${props.imgClasses || ''}`}
          src={src}
          srcSet={srcSet}
          alt={props.alt || image?.alt}
          onLoad={() => setIsLoading(false)}
        />
        {props.label && (
          <figcaption className="text-xs mt-2">
            {props.label}
          </figcaption>
        )}
      </figure>
      {isLoading && (
        <SkeletonLoader
          className={`${props.imgClasses || ''} ${props.className || ''}`}
        />
      )}
    </>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  srcSet: PropTypes.string,
  imgClasses: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  isFullWidth: PropTypes.bool
}
