// Register service worker.
export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope)

          // Check for updates and activate the new service worker immediately
          if (registration.waiting) {
            updateServiceWorker(registration.waiting)
          }

          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                updateServiceWorker(newWorker)
              }
            })
          })
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error)
        })
    })
  }
}

function updateServiceWorker(worker) {
  worker.postMessage({ action: 'skipWaiting' })
  worker.addEventListener('statechange', () => {
    if (worker.state === 'activated') {
      window.location.reload()
    }
  })
}
