import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Renders a loading component that displays a spinner while content is being loaded.
 * @param {boolean} isLoading - Indicates whether the content is currently being loaded.
 * @param {React.ReactNode} children - The content to be rendered.
 * @returns {React.ReactElement} The loading component, which displays a spinner or the children elements.
 */
export default function Loading({ isLoading, children }) {
  const [showLoader, setShowLoader] = useState(isLoading)

  useEffect(() => {
    let timeout

    if (!isLoading) {
      timeout = setTimeout(() => {
        setShowLoader(false)
        window.scrollTo(0, 0)
      }, 500)
    } else {
      setShowLoader(true)
    }

    return () => clearTimeout(timeout)
  }, [isLoading])

  return (
    <>
      {showLoader && (
        <div className={`transition-opacity duration-500 ${isLoading ? 'opacity-100' : 'opacity-0'}`}>
          <div className="bg-midnight w-full h-screen flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-tertiary"></div>
          </div>
        </div>
      )}
      {!showLoader && children}
    </>
  )
}

// PropTypes validation
Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node
}
