import React from 'react'
import PropTypes from 'prop-types'

import Image from '../general/Image.jsx'

/**
 * Renders a header image component.
 * @param {object} props The properties passed to the HeaderImage component.
 * @param {object} props.content Contains the image data to be passed to the `Image` component.
 * @returns {React.ReactElement} A div element containing the `Image` component and a gradient overlay.
 */
export default function HeaderImage({ content }) {
  return (
    <div className="absolute w-full inset-0 h-[40vh] z-20">
      <Image
        image={content?.header}
        isFullWidth
      />

      {/* Gradient Overlay */}
      <div className="absolute w-full inset-0 h-[40vh] bg-gradient-image-overlay"></div>
    </div>
  )
}

// PropTypes validation
HeaderImage.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.object
  })
}
