import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { StoryblokComponent } from '@storyblok/react'
import { Helmet } from 'react-helmet'

import { STORYBLOK_LOCATION } from '../constants/Api.js'
import HeaderImage from '../components/header/HeaderImage.jsx'
import Title from '../components/header/Title.jsx'
import Loading from '../components/general/Loading.jsx'
import Error from '../components/general/Error.jsx'
import { Arrow } from '../components/icons/Arrow.jsx'
import useStoryLoader from '../hooks/useStoryLoader.jsx'

export default function ContentPage() {
  const location = useLocation()
  const fullPath = location.pathname.substring(1) // Removes the leading slash
  const { story, loading, error } = useStoryLoader(
    STORYBLOK_LOCATION + fullPath,
    { version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE }
  )
  const navigate = useNavigate()

  if (loading) return <Loading isLoading={true} />
  if (error) return <Error message={error} />
  if (!story?.content) return <Error message="No content available" />

  return (
    <>
      <Helmet>
        <title>Pon x DGP - {story?.name}</title>
      </Helmet>
      <HeaderImage content={story?.content} />

      {/* Content */}
      <div className="relative z-20 mx-auto max-w-3xl px-4 sm:px-6 lg:px-20 2xl:px-0 text-white mt-36 md:mt-52">
        <div className="mb-12 md:mb-20">

          <Title title={story?.name} />
          <StoryblokComponent blok={story?.content} />

          {location.pathname.includes('info') && (
            <div className="text-center">
              <button className="mt-24 shadow bg-gradient-button rounded-md py-2.5 px-4 cursor-pointer text-sm font-medium inline-flex items-center gap-x-2 hover:transform hover:bg-gradient-button-reverse duration-200" onClick={() => navigate(-1)}>
                <Arrow className="fill-white rotate-180" /> Terug
              </button>
            </div>
          )}

        </div>
      </div>
    </>
  )
}
